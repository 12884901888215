import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// Cloudinary URLs for video and thumbnail
const videoPublicId = "welcome_hizoo7";
const videoSrc = `https://res.cloudinary.com/dxjvh9voy/video/upload/v1713884654/${videoPublicId}.mp4`;
const thumbnailSrc = `https://res.cloudinary.com/dxjvh9voy/video/upload/v1713884654/${videoPublicId}.jpg`;

const AboutVideoSection = () => {
  return (
    <section id="about" className="about">
      <div className="container">
        <Row>
          <Col
            lg={6}
            className="video-box align-self-baseline position-relative"
          >
            {/* Container for video and thumbnail */}
            <div style={{ position: "relative" }}>
              {/* Thumbnail */}

              {/* Video */}
              <video
                poster={thumbnailSrc}
                className="rounded-4"
                controls
                style={{ position: "relative", zIndex: 0, width: "100%" }}
              >
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </Col>
          <Col lg={6} className="pt-3 pt-lg-0 content">
            <h3
              className="text-center text-lg-left text-center-md"
              style={{ fontWeight: "bold" }}
            >
              ABOUT THE BOOTCAMP
            </h3>
            <p
              className="text-dark mt-4 text-center text-lg-left text-center-md"
              style={{ fontSize: "1.125rem", width: "90%" }}
            >
              Hi. I’m Naomi Lucas and I led the design process for The
              Employment Bootcamp. I’m not sure how you got here but I’m very
              glad you did. Watch the video to find out if The Employment
              Bootcamp is right for you. I look forward to receiving your
              application.
            </p>
            <div className="text-center text-lg-left text-center-md">
              <a href="/apply" target="blank" className="btn btn-about">
                Apply Now
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AboutVideoSection;
