import { useAtom } from "jotai";
import { formdataAtom } from "../lib/atom";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const useUpdateOther = () => {
  const router = useNavigate();
  const [formdata] = useAtom(formdataAtom);

  const updateOther = async () => {
    const email = formdata.email;
    Swal.fire({
      title: "Please confirm your answers",
      text: "You will not be able to change it after this. Continue?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#ECA239",
      cancelButtonColor: "#d2d2d7",
      confirmButtonText: "Yes, continue",
      cancelButtonText: "Edit",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const fd = new FormData();

        // Append form data
        fd.append("everLeadPeople", formdata.everLeadPeople);
        fd.append("expectedDifference", formdata.expectedDifference);
        fd.append("whatDistractsYou", formdata.whatDistractsYou);
        fd.append("is_complete", "Yes"); // HARD CODED
        fd.append(
          "whatYouFindMostChallenging",
          formdata.whatYouFindMostChallenging
        );

        const apiUrl = process.env.REACT_APP_BASE_URL + `/update/${email}`;

        try {
          const res = await axios.post(apiUrl, fd);
          console.log("Registration complete", res);
          router("/apply/success");
        } catch (error) {
          Swal.fire({
            title: "Error",
            text:
              error?.response?.data?.message || "An error occurred. Try again",
            icon: "error",
            confirmButtonColor: "#ECA239",
          });
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Almost there!",
          text: "You are almost done with your application",
          icon: "success",
          confirmButtonText: "Continue",
          confirmButtonColor: "#ECA239",
          preConfirm: async () => {
            router("/apply/success");
          },
        });
      }
    });
  };

  return { updateOther };
};
