import React from "react";
import { useNavigate } from "react-router-dom";

const SaveAndContinueWithBackButton = ({ handleComms }) => {
  const router = useNavigate();
  return (
    <>
      <button
        onClick={() => router(-1)}
        type="button"
        className="btn btn-secondary me-2"
      >
        Back
      </button>
      <button
        type="button"
        onClick={handleComms}
        className="btn btn-primary"
        style={{
          backgroundColor: "#ECA239",
          color: "black",
          border: "1px solid #ECA239",
        }}
      >
        Save and continue
      </button>
    </>
  );
};

export default SaveAndContinueWithBackButton;
