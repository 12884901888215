import React, { useEffect, useState } from "react";
import { formdataAtom, progressAtom } from "../../../lib/atom";
import { isUpdatingAtom } from "../../../lib/atom";
import { useAtom } from "jotai";
import TextArea from "../../Form/TextArea";
import { useLocation, useNavigate } from "react-router-dom";
import SaveAndContinueWithBackButton from "../../Utility/SaveAndContinueWithBackButton";
import { useUpdateOther } from "../../../hooks/useUpdateOther";
import { useFormValidation } from "../../../hooks/useFormValidation";
import ModalComponent from "./InfoModal";

const Other = () => {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [isUpdate, setIsUpdate] = useAtom(isUpdatingAtom);
  const [, setProgress] = useAtom(progressAtom);
  const [formdata, setFormdata] = useAtom(formdataAtom);
  const [errors, setErrors] = useState([]);
  const [errorText, setErrorText] = useState();
  const [isLoading, setIsloading] = useState(false);

  const { validate } = useFormValidation("others");
  const { updateOther } = useUpdateOther();

  useEffect(() => {
    setProgress(6);
  }, [location.pathname]);

  const handleModal = () => {
    if (window.confirm("You will be submitting your application. Continue ?")) {
      handleOther();
    }
  };

  const handleOther = async () => {
    const errs = await validate();
    if (errs.length) {
      setErrors(errs);
      return;
    }

    await updateOther();
  };

  return (
    <div className="col-md-8 slide-col" id="otherSection">
      <h4
        className="text-start text-uppercase fw-bold"
        style={{ color: "black", fontWeight: "bold" }}
      >
        GETTING TO KNOW YOU
      </h4>
      <form>
        <div className="mb-4">
          <TextArea
            name={"whatYouFindMostChallenging"}
            id="challenging"
            label={"What do you find most challenging?"}
            value={formdata.whatYouFindMostChallenging}
          />
        </div>
        <div className="mb-4">
          <TextArea
            name={"everLeadPeople"}
            id="lead"
            label={"Have you ever led anything?"}
            value={formdata.everLeadPeople}
          />
        </div>
        <div className="mb-4">
          <TextArea
            name={"whatDistractsYou"}
            id="whatDistractsYou"
            label={"What distracts you?"}
            value={formdata.whatDistractsYou}
          />
        </div>
        <div className="mb-4">
          <TextArea
            name={"expectedDifference"}
            id="expectedDifference"
            label={
              "What difference are you hoping The Employment Bootcamp will make in your life?"
            }
            value={formdata.expectedDifference}
          />
        </div>

        <div className="mb-4">
          {errors.map((error, index) => (
            <div key={index} className="text-start alert alert-danger">
              {error}
            </div>
          ))}
        </div>
      </form>

      <SaveAndContinueWithBackButton handleComms={handleOther} />
      <ModalComponent
        showModal={showModal}
        setShowModal={setShowModal}
        errors={errorText}
        handleModal={handleModal}
        isLoading={isLoading}
      />
    </div>
  );
};

export default Other;
