import React, { useEffect, useState } from "react";
import { progressAtom, formdataAtom, isUpdatingAtom } from "../../../lib/atom";
import {
  educationFocusValues,
  highestQualificationValues,
  adaptabilityValues,
  readinessValues,
  growthValues,
  feedbackValues,
} from "../../../constants/eligibilityConstants";
import axios from "axios";
import Swal from "sweetalert2";
import FileInput from "../../Form/FileInput";
import { useAtom } from "jotai";
import Radio from "../../Form/Radio";
import { useLocation, useNavigate } from "react-router-dom";
import SaveAndContinueWithBackButton from "../../Utility/SaveAndContinueWithBackButton";
import { useUpdateDatabase } from "../../../hooks/useUpdateDatabase";
import EligibilityMessage from "./EligibilityMessage";
import { useFormValidation } from "../../../hooks/useFormValidation";

const Eligibility = () => {
  const location = useLocation();
  const { validate } = useFormValidation("eligibility");
  const [formdata, setFormdata] = useAtom(formdataAtom);
  const [isUpdate] = useAtom(isUpdatingAtom);
  const { updateDatabase } = useUpdateDatabase();
  const router = useNavigate();
  const [, setProgress] = useAtom(progressAtom);
  const [errors, setErrors] = useState([]);

  // Cv
  const [showCvHelpText, setShowCvHelpText] = useState(false);
  const [cv, setCv] = useState(null);

  const handleFileUpload = async (uploadedCV) => {
    if (
      uploadedCV.type !== "application/pdf" &&
      uploadedCV.type !== "application/msword"
    ) {
      setErrors(["Invalid file type"]);
      setShowCvHelpText(true);
      return;
    }

    setCv(uploadedCV);
    await uploadFile(uploadedCV);
  };

  const handleCVHelp = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Unable to upload your CV?",
      text: "If you have any issues uploading your CV, send it as an email to teb.bootcamp@gmail.com",
      icon: "question",
      confirmButtonText: "I have sent it",
      confirmButtonColor: "#ECA239",
      preConfirm: () => {
        setFormdata({ ...formdata, hasUploadedCV: true });
      },
    });
  };

  const uploadFile = async (uploadedCV) => {
    setErrors([]);
    const fd = new FormData();
    fd.append("pdf", uploadedCV);
    const apiUrl = process.env.REACT_APP_BASE_URL + `/update/${formdata.email}`;
    try {
      await axios.post(apiUrl, fd);
      setFormdata({ ...formdata, hasUploadedCV: true });
    } catch (error) {
      alert(
        "Unable to upload CV. Please send it as an email to teb.bootcamp@gmail.com"
      );
    }
  };

  useEffect(() => {
    setProgress(4);
  }, [location.pathname]);

  const handleEligibility = async () => {
    setErrors([]);

    const errs = await validate();
    if (errs.length) {
      setErrors(errs);
      return;
    }

    if (!formdata.hasUploadedCV) {
      if (!cv) {
        setErrors(["Please attach your CV as a PDF or Word file."]);
        return;
      } else {
        if (
          cv.type !== "application/pdf" &&
          cv.type !== "application/msword" &&
          cv.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ) {
          setShowCvHelpText(true);
          setErrors(["Invalid file type. Please select a PDF or Word file."]);
          setCv(null);
          return;
        }
      }
    }    

    updateDatabase();
  };

  if (formdata.eligibilityStatus !== "not measured") {
    return <EligibilityMessage status={formdata.eligibilityStatus} />;
  }

  return (
    <div className="col-md-8 slide-col" id="education">
      <h4
        className="text-start text-uppercase fw-bold"
        style={{ color: "black", fontWeight: "bold" }}
      >
        ELIGIBILITY
      </h4>
      <form>
        <div className="mb-4">
          <label
            htmlFor="highestQualification"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            What is the highest post-secondary qualification you have?
          </label>
          <Radio
            items={highestQualificationValues}
            value={formdata.highestQualification}
            name={"highestQualification"}
            flex={false}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="educationFocus"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            Which best describes the main focus of your education?
          </label>
          <Radio
            items={educationFocusValues}
            value={formdata.educationFocus}
            name={"educationFocus"}
            flex={false}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="readinessFocus"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            How open are you to trying new tools or approaches in getting work
            done?
          </label>
          <Radio
            items={readinessValues}
            value={formdata.readinessFocus}
            name={"readinessFocus"}
            flex={false}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="adaptabilityFocus"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            Have you ever thought about the constant disruptions to the world of
            work? What feelings does this evoke in you?
          </label>
          <Radio
            items={adaptabilityValues}
            value={formdata.adaptabilityFocus}
            name={"adaptabilityFocus"}
            flex={false}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="feedbackFocus"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            How do you deal with feedback?
          </label>
          <Radio
            items={feedbackValues}
            value={formdata.feedbackFocus}
            name={"feedbackFocus"}
            flex={false}
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="growthFocus"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            If you are asked to perform a task you have never done before, what
            would you do?
          </label>
          <Radio
            items={growthValues}
            value={formdata.growthFocus}
            name={"growthFocus"}
            flex={false}
          />
        </div>

        <div className="mb-4">
          {formdata.hasUploadedCV ? (
            <div className="alert alert-info">CV has been uploaded</div>
          ) : (
            <FileInput
              onChangeFunc={handleFileUpload}
              required={true}
              label={"Upload your CV (PDF or Word file) *"}
              accept={"application/*"}
            />
          )}

          {showCvHelpText && (
            <a href="#" className="text-info mt-2" onClick={handleCVHelp}>
              Help
            </a>
          )}
        </div>

        <div className="mb-3 form-check text-end">
          {errors.map((error, index) => (
            <div key={index} className="alert alert-danger text-start">
              {error}
            </div>
          ))}
          <SaveAndContinueWithBackButton handleComms={handleEligibility} />
        </div>
      </form>
    </div>
  );
};

export default Eligibility;
