  export const highestQualificationValues = [
    {
      value: "Vocational degree",
      label: "Vocational degree (E.g. Catering, Fashion Design, Plumbing)",
    },
    {
      value: "Ordinary/Higher National Diploma or Bachelors degree",
      label: "Ordinary/Higher National Diploma or Bachelors degree",
    },
    {
      value: "Post-Graduate Degree (PGD, Masters, PHD or equivalent)",
      label: "Post-Graduate Degree (PGD, Masters, PHD or equivalent)",
    },
    {
      value: "I don’t have a post-secondary qualification",
      label: "I don’t have a post-secondary qualification",
    }
  ];

  export const educationFocusValues = [
    {
      value: "Applied Sciences",
      label:
        "Applied Sciences (E.g. Architecture, Law, Business, Engineering, Medicine etc.)",
    },
    {
      value: "Formal and Natural Sciences",
      label:
        "Formal and Natural Sciences (E.g. Statistics, Computer Science, Mathematics, Geology, Chemistry, Physics etc.)",
    },
    {
      value: "Social Sciences and Humanities",
      label:
        "Social Sciences and Humanities (E.g. Psychology, Economics, Political Science, Arts, Philosophy, History, Linguistics, Religion etc.)",
    },
    {
      value: "Vocational",
      label:
        "Vocational (E.g. Plumbing, Catering, Electrical Works, Carpentry, Home Cleaning, Arts and Crafts, Masonry, Photography, Fashion Design etc.)",
    },
  ];

  export const readinessValues = [
    {
      value: "I experiment with new tools and approaches all the time.",
      label: "I experiment with new tools and approaches all the time.",
    },
    {
      value: "If I find a tool or approach that delivers better results, I will certainly use it.",
      label: "If I find a tool or approach that delivers better results, I will certainly use it.",
    },
    {
      value: "It depends on the tool or approach. If it is easy to use or adopt, why not? If it is complicated, I may stick with what I know.",
      label: "It depends on the tool or approach. If it is easy to use or adopt, why not? If it is complicated, I may stick with what I know.",
    },
    {
      value: "I do as I am told, if it’s left to me, I stick with what I know.",
      label: "I do as I am told, if it’s left to me, I stick with what I know.",
    },
  ];

  export const adaptabilityValues = [
    {
      value: "Excitement: Change is inevitable. I see the disruptions as an opportunity to grow and evolve.",
      label: "Excitement: Change is inevitable. I see the disruptions as an opportunity to grow and evolve.",
    },
    {
      value: "Determination: To continuously learn and upskill to stay relevant.",
      label: "Determination: To continuously learn and upskill to stay relevant.",
    },
    {
      value: "Curiosity: I’m motivated to stay abreast of new trends and technologies.",
      label: "Curiosity: I’m motivated to stay abreast of new trends and technologies.",
    },
    {
      value: "Anxiety: I feel overwhelmed by the constant changes.",
      label: "Anxiety: I feel overwhelmed by the constant changes.",
    },
  ];

  export const feedbackValues = [
    {
      value: "I actively give and solicit for feedback. It’s important for my growth and learning.",
      label: "I actively give and solicit for feedback. It’s important for my growth and learning.",
    },
    {
      value: "I appreciate feedback but I don’t actively seek it.",
      label: "I appreciate feedback but I don’t actively seek it.",
    },
    {
      value: "I am indifferent to feedback. I do what I think is best.",
      label: "I am indifferent to feedback. I do what I think is best.",
    },
    {
      value: "I dislike feedback. It makes me uncomfortable.",
      label: "I dislike feedback. It makes me uncomfortable.",
    },
  ];

  export const growthValues = [
    {
      value: "I would research and learn how to do it.",
      label: "I would research and learn how to do it.",
    },
    {
      value: "I would ask for help from someone who knows how to do it.",
      label: "I would ask for help from someone who knows how to do it.",
    },
    {
      value: "I would try to figure it out on my own.",
      label: "I would try to figure it out on my own.",
    },
    {
      value: "I would avoid doing it if possible.",
      label: "I would avoid doing it if possible.",
    },
  ];