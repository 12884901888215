import React from "react";
import { Modal } from "react-bootstrap";

const ModalComponent = ({ showModal, setShowModal, errors = [], handleModal, isLoading }) => {
  return (
    <Modal show={showModal} onHide={() => setShowModal(false)}>
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h4
              className="text-start text-uppercase fw-bold modal-title"
              style={{ color: "black", fontWeight: "bold" }}
            >
              Key Information:
            </h4>
          </div>
          <div className="modal-body">
            <div className="col-md-12 slide-col" id="keyInformation">
              <p
                className="fw-bold"
                style={{ color: "black", fontWeight: "bold" }}
              >
                By submitting your application, you confirm that you
                understand and agree with the following:
              </p>
              <ul style={{ color: "black" }}>
                <li>
                  You are available and ready to join the next cohort of The Employment Bootcamp.
                </li>
                <li>You are open-minded and ready to do what it takes to see the results you require.</li>
                <li>
                  <b>Payment of commitment fee:</b>
                  <ul>
                    <li>
                      <b>Nigerian Applicants:</b> NGN60,000
                    </li>
                    <li>
                      <b>International Applicants:</b> 50 US dollars
                    </li>
                    <li>
                      <b>Applicants from Partners:</b> Discounts are as agreed with our partners. Please confirm details from your organisation.
                    </li>
                  </ul>
                </li>
              </ul>
              {errors.map((error, index) => (
                <div key={index} className="alert alert-danger text-start">
                  {error}
                </div>
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn closeModal btn-secondary"
              onClick={() => setShowModal(false)}
            >
              Back
            </button>

            {isLoading ? (
              <button
                disabled
                type="button"
                className="btn btn-primary"
                id="btnLoading"
              >
                <div
                  className="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleModal}
                style={{
                  backgroundColor: "#ECA239",
                  color: "black",
                  border: "1px solid #ECA239",
                }}
              >
                submit
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalComponent;