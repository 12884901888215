import React, { useEffect, useState } from "react";
import { progressAtom, formdataAtom } from "../../../lib/atom";
import { useAtom } from "jotai";
import Radio from "../../Form/Radio";
import Range from "../../Form/Range";
import { useNavigate } from "react-router-dom";
import TextArea from "../../Form/TextArea";
import { useUpdateEmployment } from "../../../hooks/useUpdateEmployment";
import {
  employmentStatusValues,
  unemployedDurationValues,
  workExperienceValues,
} from "../../../constants/employmentConstants"; // Ensure these constants are imported
import { useFormValidation } from "../../../hooks/useFormValidation";
import SaveAndContinueWithBackButton from "../../Utility/SaveAndContinueWithBackButton";

const Employment = () => {
  const router = useNavigate();

  const [, setProgress] = useAtom(progressAtom);
  const [formdata, setFormdata] = useAtom(formdataAtom);
  const { validate } = useFormValidation("employment");
  const { updateEmploymentToDatabase } = useUpdateEmployment();
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setProgress(5);
  }, []);

  const handleEmploymentStatusChange = (newStatus) => {
    setFormdata((prev) => ({
      ...prev,
      employmentStatus: newStatus,
      unemployedDuration: newStatus === "I am unemployed and actively looking for a job" ? prev.unemployedDuration : "",
      whyLeaveCurrentPosition: newStatus === "I am employed but looking for another job" ? prev.whyLeaveCurrentPosition : "",
      whenLeavingCurrentPosition: newStatus === "I am employed but looking for another job" ? prev.whenLeavingCurrentPosition : "",
      whenWillYouBeDoneServing: newStatus === "I am currently serving (NYSC)" ? prev.whenWillYouBeDoneServing : "",
    }));
  };

  const handleEmployment = async () => {
    // Validate the component fields
    const errs = await validate(); // Make sure to await this call
    if (errs.length) {
      setErrors(errs);
      return;
    }

    await updateEmploymentToDatabase(); // Make sure this returns a promise
  };

  return (
    <div className="col-md-8 slide-col" id="employment">
      <h4 className="text-start text-uppercase fw-bold">EMPLOYMENT STATUS</h4>
      <form onSubmit={(e) => e.preventDefault()}>
        <div className="mb-4">
          <label
            htmlFor="employmentStatus1"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            What is your current employment status?
          </label>

          <Radio
            items={employmentStatusValues}
            name={"employmentStatus"}
            value={formdata.employmentStatus}
            onChange={handleEmploymentStatusChange}
            flex={false}
          />
        </div>

        {formdata.employmentStatus ===
          "I am unemployed and actively looking for a job" && (
          <div className="mb-4" id="unemployedDiv">
            <label
              htmlFor="unemployedInput1"
              className="form-label"
              style={{ color: "black", fontWeight: "bold" }}
            >
              How long have you been out of a job? (Pick only one option).
            </label>

            <Radio
              items={unemployedDurationValues}
              name={"unemployedDuration"}
              value={formdata.unemployedDuration}
              flex={false}
            />
          </div>
        )}

        {formdata.employmentStatus ===
          "I am employed but looking for another job" && (
          <div className="mb-4" id="freelancerDiv">
            <TextArea
              label={"Why do you want to leave where you are now?"}
              name={"whyLeaveCurrentPosition"}
              value={formdata.whyLeaveCurrentPosition}
            />
          </div>
        )}

        {formdata.employmentStatus ===
          "I am employed but looking for another job" && (
          <div className="mb-4" id="">
            <TextArea
              label={"When are you looking to leave?"}
              name={"whenLeavingCurrentPosition"}
              value={formdata.whenLeavingCurrentPosition}
            />
          </div>
        )}

        {formdata.employmentStatus === "I am currently serving (NYSC)" && (
          <div className="mb-4" id="freelancerDiv">
            <TextArea
              label={"When will you be done serving? (Short answer text)"}
              name={"whenWillYouBeDoneServing"}
              value={formdata.whenWillYouBeDoneServing}
            />
          </div>
        )}

        <div className="mb-4">
          <label
            htmlFor="employmentStatus1"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            How many years of work experience do you have? (Pick only one
            option).
          </label>

          <Radio
            items={workExperienceValues}
            name={"workExperience"}
            value={formdata.workExperience}
            flex={false}
          />
        </div>

        <div className="mb-4">
          <label
            htmlFor="jobRange"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            On a scale of 1-10, how badly do you need a job? Drag the button as
            needed.
            <span className="d-block">
              (1 - 4 = I don’t really need a job. 5 - 7 = I need a job, 8 - 10 =
              I desperately need a job)
            </span>
          </label>
          <Range
            name={"jobRange"}
            min={"1"}
            max={"10"}
            step={"1"}
            value={formdata.jobRange}
          />
        </div>

        <div className="mb-3 form-check text-end">
          {errors.map((error, index) => (
            <div key={index} className="alert alert-danger text-start">
              {error}
            </div>
          ))}

          <SaveAndContinueWithBackButton handleComms={handleEmployment} />
        </div>
      </form>
    </div>
  );
};

export default Employment;
