import {
  readinessValues,
  adaptabilityValues,
  feedbackValues,
  growthValues,
} from "../../constants/eligibilityConstants";

export const calculateOverallEligibility = ({
  highestQualification,
  readinessFocus,
  adaptabilityFocus,
  feedbackFocus,
  growthFocus,
}) => {
  const scores = {
    highestQualification:
      highestQualification === "I don’t have a post-secondary qualification"
        ? 4
        : 1,
    readinessFocus:
      readinessValues.findIndex((item) => item.value === readinessFocus) + 1,
    adaptabilityFocus:
      adaptabilityValues.findIndex((item) => item.value === adaptabilityFocus) +
      1,
    feedbackFocus:
      feedbackValues.findIndex((item) => item.value === feedbackFocus) + 1,
    growthFocus:
      growthValues.findIndex((item) => item.value === growthFocus) + 1,
  };

  const ineligibleCount = Object.values(scores).filter(
    (score) => score >= 4
  ).length;

  if (ineligibleCount >= 3) {
    return "ineligible";
  } else if (ineligibleCount === 2 || scores.highestQualification === 4) {
    return "review";
  } else {
    return "eligible";
  }
};
