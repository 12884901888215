import React from 'react';

const CurvyLine = () => {
  const screenWidth = window.innerWidth;
  let svg;

  if (screenWidth >= 1080) {
    svg = (
      <svg className="curvy-line" viewBox="0 0 1000 1700" xmlns="http://www.w3.org/2000/svg">
        <path d="M409.196,70.56923 L528.68,99.4082 C680.68,82 108.68,473 670.68,300 
          C50,880 300,300 550,630 C550,730 120,720 700,830 C100,1030 420,1140 600,1030 
          C1100,990 820,1099 700,1290" fill="none" stroke="#eca239" strokeWidth="4" stroke-linecap="round" />
      </svg>
    );
  } else if (screenWidth >= 1024) {
    svg = (
      <svg className="curvy-line" viewBox="0 0 1000 4000" xmlns="http://www.w3.org/2000/svg">
        <path d="M505,50 L505,2600" fill="none" stroke="#eca239" strokeWidth="4" stroke-linecap="round" />
      </svg>
    );
  } else {
    svg = (
      <svg className="curvy-line" viewBox="0 0 1000 4000" xmlns="http://www.w3.org/2000/svg">
        <path d="M509,50 L509,3600" fill="none" stroke="#eca239" strokeWidth="4" stroke-linecap="round" />
      </svg>
    );
  }

  return svg;
};

export default CurvyLine;
