import { useAtom } from "jotai";
import { formdataAtom, isUpdatingAtom } from "../lib/atom";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react"; // Import useState for managing loading and submission states
import { calculateOverallEligibility } from "../components/Utility/calculateOverallEligibilty"; // Ensure this function is correctly imported

export const useUpdateDatabase = () => {
  const router = useNavigate();
  const [formdata, setFormdata] = useAtom(formdataAtom);
  const [, setIsUpdate] = useAtom(isUpdatingAtom);

  // Define loading and submission state variables
  const [, setIsLoading] = useState(false);

  const updateDatabase = async () => {
    Swal.fire({
      title: "Please confirm your answers",
      text: "You will not be able to change it after this. Continue?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#ECA239",
      cancelButtonColor: "#d2d2d7",
      confirmButtonText: "Yes, continue",
      cancelButtonText: "Edit",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const fd = new FormData();

        // Append form data
        fd.append("highestQualification", formdata.highestQualification);
        fd.append("educationFocus", formdata.educationFocus);
        fd.append("growthFocus", formdata.growthFocus);
        fd.append("feedbackFocus", formdata.feedbackFocus);
        fd.append("adaptabilityFocus", formdata.adaptabilityFocus);
        fd.append("readinessFocus", formdata.readinessFocus);

        // Calculate overall eligibility
        const overallEligibility = calculateOverallEligibility(formdata);
        fd.append("eligibilityStatus", overallEligibility);

        setFormdata({ ...formdata, eligibilityStatus: overallEligibility });

        // Extract email from formdata
        const email = formdata.email;

        const apiUrl = process.env.REACT_APP_BASE_URL + `/update/${email}`;

        try {
          const res = await axios.post(apiUrl, fd);
          setIsLoading(false);
          setIsUpdate(true);
          console.log(res);
        } catch (error) {
          console.log("ERRR::", error);
          const msg =
            error?.response?.data?.message || "An error occurred. Try again";
          return Swal.showValidationMessage(msg);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Almost there!",
          text: "You are almost done with your application",
          icon: "success",
          confirmButtonText: "Continue",
          confirmButtonColor: "#ECA239",
          preConfirm: async () => {
            router("/apply/employment");
          },
        });
      }
    });
  };

  return { updateDatabase };
};
