import React from "react";

import animationgif from "../../assets/image/animationgif.gif";

const Journey = () => {
  return (
    <section id="whatsItGonnaBe" className="pb-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center CTA" style={{ minWidth: "50%" }}>
            <div className="section-title">
              <h2 className="text-center text-black font-family-Raleway">
                <span>
                  <svg
                    style={{ marginTop: "-40px" }}
                    width="23"
                    height="23"
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M12.0705 20.9499C9.0984 20.2356 6.23627 20.2823 3.22162 20.1967C2.63287 20.1801 2.1434 20.632 2.12567 21.2057C2.10794 21.7796 2.57256 22.2586 3.16131 22.2756C6.01991 22.3567 8.74017 22.2912 11.5633 22.9691C12.1343 23.1064 12.7124 22.765 12.8507 22.2077C12.9926 21.6507 12.6415 21.0868 12.0705 20.9499Z"
                      fill="#161B23"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M17.8309 14.8815C13.2983 10.0355 7.46761 5.46945 1.58016 2.27801C1.0659 1.9997 0.416831 2.18098 0.133099 2.68285C-0.150634 3.18471 0.0338085 3.81828 0.548072 4.09659C6.24045 7.18266 11.8797 11.5986 16.2598 16.2846C16.657 16.7081 17.3309 16.7375 17.7636 16.3504C18.1963 15.9633 18.2282 15.3051 17.8309 14.8815Z"
                      fill="#161B23"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M22.7834 13.6539C23.5318 9.22171 22.255 4.65326 20.4498 0.62307C20.2121 0.0972884 19.5844 -0.142562 19.0488 0.0872304C18.5097 0.317368 18.265 0.93119 18.4991 1.45698C20.1447 5.12741 21.3648 9.27855 20.6838 13.3153C20.5881 13.8817 20.9818 14.4172 21.5599 14.5104C22.138 14.604 22.6877 14.2199 22.7834 13.6539Z"
                      fill="#161B23"
                    />
                  </svg>
                </span>
                SO, WHAT IS IT GOING TO BE?
              </h2>
            </div>
            <img src={animationgif} class="img-fluid" alt="Whats It Gonna Be" />
            <div className="text-center">
              <a href="/apply" target="blank" className="btn-orange">
                Apply Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Journey;
