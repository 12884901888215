import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = ({ paths = [] }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (Array.isArray(paths) && paths.includes(pathname)) {
      window.scrollTo(0, 0);
    }
  }, [pathname, paths]);

  return null;
};

export default ScrollToTop;