import React from "react";
import { useNavigate } from "react-router-dom";
import handshake from "../../../assets/img/handshake.gif";
import check from "../../../assets/img/check_mark1.gif";

const EligibilityMessage = ({ status }) => {
  const router = useNavigate();

  return (
    <div className="col-md-8 mt-5 slide-col text-center">
      {status === "eligible" ? (
        <>
          <div className="alert alert-success">
            <div>
              <img src={check} alt="success" width={100} />
              <p className="pt-4">
                Congratulations, you are eligible to apply for The Employment
                Bootcamp. Please proceed with your application.
              </p>
            </div>
          </div>
          <div>
            <button
              onClick={() => router("/apply/employment")}
              type="button"
              className="btn btn-primary float-end"
              style={{
                backgroundColor: "#ECA239",
                color: "black",
                border: "1px solid #ECA239",
              }}
            >
              Proceed
            </button>
          </div>
        </>
      ) : (
        <div className="alert alert-success">
          <img src={handshake} alt="handshake gesture" width={100} />
          <p>
            Well done for coming this far. Your application is under review. You
            will hear back from us shortly.
          </p>
        </div>
      )}
    </div>
  );
};

export default EligibilityMessage;
