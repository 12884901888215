import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import { useAtom } from "jotai";
import { formdataAtom, selectedDateAtom } from "../../lib/atom";

const SelectionConfirmation = () => {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const [formdata] = useAtom(formdataAtom);
  const [, setSelectedDate] = useAtom(selectedDateAtom);
  const [reminderSet, setReminderSet] = useState(false);
  const [disableExitIntent, setDisableExitIntent] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const checkIfMobile = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };

  useEffect(() => {
    setIsMobile(checkIfMobile());
  }, []);

  const triggerReminderPopup = async () => {
    if (!isMobile && !disableExitIntent) {
      const result = await MySwal.fire({
        title: "Before you go, would you like to set a reminder? Thank you!",
        icon: "info",
        showCancelButton: false,
        confirmButtonText: "Set Reminder",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      if (result.isConfirmed) {
        setReminderSet(true);
        setShowDatePicker(true);
      }
    }
  };

  const handleBeforeUnload = (event) => {
    if (!reminderSet && !disableExitIntent) {
      event.preventDefault();
      event.returnValue = "";
      return "";
    }
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [reminderSet, disableExitIntent]);

  useEffect(() => {
    const handleExitIntent = (event) => {
      if (!reminderSet && !disableExitIntent) {
        if (event.type === "mouseleave" && event.clientY <= 0) {
          triggerReminderPopup();
        } else if (event.type === "touchstart" && !isMobile && !disableExitIntent) {
          triggerReminderPopup();
        }
      }
    };

    document.addEventListener("mouseleave", handleExitIntent);
    document.addEventListener("touchstart", handleExitIntent);

    return () => {
      document.removeEventListener("mouseleave", handleExitIntent);
      document.removeEventListener("touchstart", handleExitIntent);
    };
  }, [reminderSet, disableExitIntent, isMobile]);

  const handlePaymentFailure = (message) => {
    setIsLoading(false);
    MySwal.fire(
      "Error",
      message || "Payment failed, please try again.",
      "error"
    );
    navigate("/failed");
  };

  const handlePayment = async () => {
    setDisableExitIntent(true);
    const paymentUrlNigeria = "https://paystack.com/buy/teblocal";
    const paymentUrlInternational = "https://paystack.com/buy/tebint";
    setIsLoading(true);
  
    try {
      let userCountry;
  
      // First, try to fetch the user's country using the ipgeolocation.io API
      try {
        const geolocationResponse = await fetch(
          "https://api.ipgeolocation.io/ipgeo?apiKey=042ee85d77d74e1c890e012cd76c8c55"
        );
        
        // Check if the request limit is reached (could check status code or response data)
        if (geolocationResponse.status === 429 || geolocationResponse.status === 403) {
          throw new Error("API limit reached");
        }
  
        const geolocationData = await geolocationResponse.json();
        userCountry = geolocationData.country_name.toLowerCase();
      } catch (error) {
        // Fallback: use country from formdata if the API call fails
        console.warn("Geolocation API failed, using formdata country:", error);
        const { country } = formdata;
        userCountry = country.trim().toLowerCase();
      }
  
      // Determine the payment URL based on the user's country
      const paymentUrl =
        userCountry === "nigeria" ? paymentUrlNigeria : paymentUrlInternational;
  
      const paymentWindow = window.open(paymentUrl, "_blank");
  
      if (paymentWindow) {
        const paymentCheckInterval = setInterval(() => {
          if (paymentWindow.closed) {
            clearInterval(paymentCheckInterval);
            triggerReminderPopup();
            setDisableExitIntent(false);
          }
        }, 1000);
      }
    } catch (error) {
      console.error("Payment failed:", error);
      handlePaymentFailure("Payment failed due to an unexpected error.");
    } finally {
      setIsLoading(false);
      setDisableExitIntent(false);
    }
  };  
  

  const submitReminder = async () => {
    if (!startDate) {
      MySwal.fire("Error", "Please select a date and time", "error");
      return;
    }

    const fd = new FormData();
    setIsLoading(true);
    fd.append("email", formdata.email);
    fd.append("reminderDate", startDate.toISOString());

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/update/${formdata.email}`;

    try {
      await axios.post(apiUrl, fd);
      setSelectedDate({ reminderDate: startDate });
      localStorage.setItem("reminderSet", "true");
      setReminderSet(true);

      MySwal.fire("Success", "Reminder has been set!", "success").then(() => {
        setShowDatePicker(false);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      });
    } catch (error) {
      console.error("Failed to set reminder:", error);
      setIsLoading(false);
      MySwal.fire("Error", "Failed to set reminder", "error");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="col-md-8 mt-5 slide-col text-center">
      {showDatePicker ? (
        <>
          <div>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date || new Date())}
              inline
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="MMMM d, yyyy h:mm aa"
              monthsShown={1}
              className="custom-datepicker"
            />
          </div>
          <div className="pt-4">
            <button
              type="button"
              onClick={submitReminder}
              className="btn btn-primary float-end"
              style={{
                backgroundColor: "#ECA239",
                color: "black",
                border: "1px solid #ECA239",
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Set Reminder"
              )}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="alert alert-success fw-bold">
            Congratulations. 
            <br />
            You have been accepted into the next cohort of The
            Employment Bootcamp. 
            <br />
            To secure your spot in the program, you are
            expected to pay a commitment fee. 
            <br />
            Please click the button below to
            proceed. 
            <br />
            Thank you.
          </div>
          <div className="float-end pt-5">
            <button
              type="button"
              onClick={handlePayment}
              className="btn btn-primary"
              style={{
                backgroundColor: "#ECA239",
                color: "black",
                border: "1px solid #ECA239",
              }}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : (
                "Proceed to payment"
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default SelectionConfirmation;