import React from "react";

const FileInput = ({ label, name, onChangeFunc, accept }) => {
  return (
    <>
      {label && (
        <label htmlFor={name} className="form-label" style={{color: "black", fontWeight: "bold"}}>
          {label}
        </label>
      )}
      <input
        type="file"
        className="form-control"
        id={name}
        name={name}
        accept={accept}
        onChange={(e) => {
          const file = e.target.files[0];
          const fileSize = file.size / (1024 * 1024);
          console.log(file);
          if (fileSize > 10) {
            alert("File size should not be morethan 10MB");
            return;
          }

          onChangeFunc(file);
        }}
      />
    </>
  );
};

export default FileInput;
