import { useAtom } from "jotai";
import { formdataAtom, isUpdatingAtom } from "../lib/atom";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

export const useUpdateEmployment = () => {
  const router = useNavigate();
  const [formdata] = useAtom(formdataAtom);

  const updateEmploymentToDatabase = async () => {
    //console.log("Updating employment data");
    Swal.fire({
      title: "Please confirm your answers",
      text: "You will not be able to change it after this. Continue?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#ECA239",
      cancelButtonColor: "#d2d2d7",
      confirmButtonText: "Yes, continue",
      cancelButtonText: "Edit",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const fd = new FormData();

        // Append form data
        fd.append("employmentStatus", formdata.employmentStatus);
        fd.append("unemployedDuration", formdata.unemployedDuration);
        fd.append("workExperience", formdata.workExperience);
        fd.append(
          "whenWillYouBeDoneServing",
          formdata.whenWillYouBeDoneServing
        );
        fd.append("whyLeaveCurrentPosition", formdata.whyLeaveCurrentPosition);
        fd.append(
          "whenLeavingCurrentPosition",
          formdata.whenLeavingCurrentPosition
        );
        // fd.append("freelancerText", formdata.freelancerText);
        fd.append("jobRange", formdata.jobRange);

        // Extract email from formdata
        const email = formdata.email;

        const apiUrl = process.env.REACT_APP_BASE_URL + `/update/${email}`;

        try {
          const res = await axios.post(apiUrl, fd);
          console.log(res);
        } catch (error) {
          console.log("ERRR::", error);
          const msg =
            error?.response?.data?.message || "An error occurred. Try again";
          return Swal.showValidationMessage(msg);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Almost there!",
          text: "You are almost done with your application",
          icon: "success",
          confirmButtonText: "Continue",
          confirmButtonColor: "#ECA239",
          preConfirm: async () => {
            router("/apply/other");
          },
        });
      }
    });
  };

  return { updateEmploymentToDatabase };
};



// import { useAtom } from "jotai";
// import { formdataAtom, isUpdatingAtom } from "../lib/atom";
// import Swal from "sweetalert2";
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// import { useState } from "react";

// export const useUpdateEmployment = () => {
//   const router = useNavigate();
//   const [formdata] = useAtom(formdataAtom);

//   const updateEmploymentToDatabase = async () => {
//     Swal.fire({
//       title: "Please confirm your answers",
//       text: "You will not be able to change it after this. Continue?",
//       icon: "info",
//       showCancelButton: true,
//       confirmButtonColor: "#ECA239",
//       cancelButtonColor: "#d2d2d7",
//       confirmButtonText: "Yes, continue",
//       cancelButtonText: "Edit",
//       showLoaderOnConfirm: true,
//       preConfirm: async () => {
//         console.log("Form data would be sent to backend:", formdata);
  
//         // Simulate successful API call
//         // Comment out axios.post and replace with simulated success message
//         // const res = await axios.post(apiUrl, fd);
//         // console.log(res);
  
//         return true; // Simulate success
//       },
//       allowOutsideClick: () => !Swal.isLoading(),
//     }).then((result) => {
//       if (result.isConfirmed) {
//         Swal.fire({
//           title: "Almost there!",
//           text: "You are almost done with your application",
//           icon: "success",
//           confirmButtonText: "Continue",
//           confirmButtonColor: "#ECA239",
//           preConfirm: async () => {
//             router("/apply/other");
//           },
//         });
//       }
//     });
//   };
    
//   return { updateEmploymentToDatabase };
// };
