import React from "react";
import NavBar from "../components/Navbar/Navbar";
import Hero from "../components/Home/Hero";
import About from "../components/Home/About";
import Bootcamp from "../components/Home/Bootcamp";
import KeyInfo from "../components/Home/KeyInfo";
import Benefit from "../components/Home/Benefit";
import Why from "../components/Home/Why";
import Faculty from "../components/Home/Faculty";
import Testimony from "../components/Home/Testimony";
import Partner from "../components/Home/Partners";
import Journey from "../components/Home/Journey";
import FAQ from "../components/Home/FAQ";
import Footer from "../components/Footer/Footer";


const LandingPage = () => {
  return (
    <>
      <NavBar/>
      <Hero/>
      <About/>
      <Bootcamp/>
      <KeyInfo/>
      <Benefit/>
      <Why/>
      <Faculty/>
      <Testimony/>
      <Partner/>
      <Journey/>
      <FAQ/>
      <Footer/>
    </>
  );
};

export default LandingPage;
