import { useAtom } from "jotai";
import {
  videoProgressAtom,
  isVideoEndedAtom,
  attemptCounterAtom,
  formdataAtom,
} from "../lib/atom";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const useSendProgress = () => {
  const router = useNavigate();
  const [formdata] = useAtom(formdataAtom);
  const [videoProgress] = useAtom(videoProgressAtom); // Video progress in seconds
  const [isVideoEnded] = useAtom(isVideoEndedAtom);
  const [attemptCounter, setAttemptCounter] = useAtom(attemptCounterAtom);

  const sendVideoProgressToBackend = async () => {
    Swal.fire({
      title: "Do you want to submit your video progress?",
      text: "Once submitted, you can't change your progress.",
      icon: "info",
      showCancelButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonColor: "#ECA239",
      cancelButtonColor: "#d2d2d7",
      confirmButtonText: "Yes, submit",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const fd = new FormData();

        const videoData = [
          { videoProgress: videoProgress },
          { isVideoEnded: isVideoEnded },
          { attemptCounter: attemptCounter },
        ];

        fd.append("videoData", videoData);

        // Extract email from formdata
        const email = formdata.email;
        const apiUrl = process.env.REACT_APP_BASE_URL + `/update/${email}`;

        try {
          const res = await axios.post(apiUrl, fd);
          console.log(res);
        } catch (error) {
          console.log("ERRR::", error);
          const msg =
            error?.response?.data?.message || "An error occurred. Try again";
          return Swal.showValidationMessage(msg);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Progress Saved!",
          text: "Your video progress has been submitted successfully.",
          icon: "success",
          confirmButtonColor: "#ECA239",
        });
      }
    });
  };

  return { sendVideoProgressToBackend };
};