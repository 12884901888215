import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import logo from "../../assets/img/logo.png";

import "./Navbar.css";

function NavBar() {
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center justify-content-between">
        <a href="/" className="logo">
          <img src={logo} alt="logo" className="img-fluid" />
        </a>

        <Navbar id="navbar" className="navbar navbar-dark" expand="md">
          <NavbarToggler onClick={toggleNavbar} className="me-2" />
          <Collapse isOpen={!collapsed} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink href="#about">About</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#benefits">Curriculum</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#services">Why Apply</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#faculty">Faculty</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#testimovy">Testimonials</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#faq">FAQ</NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="btn-orange nav-button"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    const url = window.location.origin + "/apply";
                    window.open(url, "_blank");
                  }}
                >
                  <span className="text-black">Apply Now</span>
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </header>
  );
}

export default NavBar;
