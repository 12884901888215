import React, { useEffect, useState } from "react";
import TextInputField from "../Form/TextInputField";
import {
  emailAtom,
  isUpdatingAtom,
  progressAtom,
  formdataAtom,
  cohortAtom,
} from "../../lib/atom";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { useFormValidation } from "../../hooks/useFormValidation";
import { useSaveEmailToDatabase } from "../../hooks/useSaveEmailToDatabase";
import SaveAndContinueButton from "../Utility/SaveAndContinueButton";
import axios from "axios";

const Login = () => {
  const router = useNavigate();
  const [formdata] = useAtom(formdataAtom);
  const { validate, validateEmail } = useFormValidation("login");
  const { saveEmailToDatabase } = useSaveEmailToDatabase();
  const [errors, setErrors] = useState([]);
  const [isUpdate] = useAtom(isUpdatingAtom);
  const [progress, setProgress] = useAtom(progressAtom);
  const [, setCohort] = useAtom(cohortAtom);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    async function getCohort() {
      await getLatestCohort();
    }
    getCohort();
    setProgress(1);
  }, [setProgress]);

  const handleLogin = async () => {
    setErrors([]);

    const errs = await validate();
    if (errs.length) {
      setErrors(errs);
      return;
    }

    const emailIsValid = validateEmail(formdata.email);
    if (!emailIsValid) {
      setErrors(["The provided email is not valid"]);
      return;
    }

    if (isUpdate) {
      router("/apply/biodata");
    } else {
      try {
        await saveEmailToDatabase();
        setProgress(progress + 1);
      } catch (error) {
        setErrors(["Unable to fetch email data, try again"]);
      }
    }
  };

  const getLatestCohort = async () => {
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/latest`;
    try {
      setLoading(true);
      const response = await axios.get(apiUrl);
      setCohort({
        id: response.data.data[0].id,
        name: response.data.data[0].name,
      });
    } catch (err) {
      console.log(err);
      setErrors([err.toString()]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="col-md-8 slide-col" id="Login">
      <h4 className="text-start text-uppercase fw-bold">get started</h4>
      {isLoading ? (
        <h4>Loading...</h4>
      ) : (
        <form>
          <div className="mb-4">
            <TextInputField
              name={"email"}
              type={"email"}
              placeholder={"jane@naomilucas.xyz"}
              label={"What is your email address?"}
              value={formdata.email}
            />
          </div>
          <div className="mb-3 form-check text-end">
            {errors.map((error, index) => (
              <div key={index} className="alert alert-danger text-start">
                {error}
              </div>
            ))}

            {!errors.length && (
              <SaveAndContinueButton handleForm={handleLogin} />
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default Login;
