import React, { useEffect, useState } from "react";

function Hero() {
    const headText = [
        "FIND MEANINGFUL WORK IN AS LITTLE AS 12 WEEKS.",
        "FOR RECENT GRADUATES LOOKING FOR THEIR FIRST JOB.",
        "FOR THOSE WHO ARE TIRED OF SEARCHING WITHOUT SUCCESS.",
        "FOR THE CHRONICALLY UNEMPLOYED.",
        "FOR THOSE WHO ARE EMPLOYED BUT UNHAPPY.",
        "FOR THOSE WHO ARE EMPLOYED BUT FEEL STAGNANT.",
        "FOR THOSE WHO HAVE RUN OUT OF OPTIONS.",
        // "BOOTCAMP STARTS 30TH OCTOBER 2023",
      ];
      const bodyText = [
        "It is new, disruptive and brutally effective.",
        "Start your career with the right foundation.",
        "Create your game plan and gain access to global job opportunities",
        "Learn how to create your own opportunities and go into the future with confidence.",
        "Take charge of your career. Get clarity and direction for your next move.",
        "Force yourself out of complacency. Create a roadmap to your dream future.",
        "Find meaningful work using our tried and tested strategies. ",
        // <CTAButton />,
      ];
    
      const [index, setIndex] = useState(0);
      const [fadeClass, setFadeClass] = useState("fadeIn");
    
      useEffect(() => {
        const timeout = setInterval(() => {
          console.log(headText[index], bodyText[index], index);
          if (index == headText.length - 1) {
            setIndex(0);
          } else {
            setIndex(index + 1);
          }
        }, 6000);
    
        return () => {
          clearInterval(timeout);
        };
      }, [index]);

  return (
    <section id="hero" className="d-flex align-items-center">
      <div className="container position-relative">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9 text-center mt-5">
            <h2 className="text-black">THE EMPLOYMENT BOOTCAMP</h2>
            <h1
              className={`fw-bold ${fadeClass}`}
              style={{ color: "#ECA239", fontFamily: "Raleway, sans-serif" }}
            >
              {headText[index]}
            </h1>
            <p className={`text-dark ${fadeClass}`}>
              {bodyText[index]}
            </p>
          </div>
        </div>
        <div className="text-center">
          <a href="/apply" target="blank" className="btn-get-started scrollto">
            Apply Now
          </a>
        </div>

        <svg
          width="176"
          height="109"
          viewBox="0 0 176 109"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="svg-bottom"
          style={{ marginLeft: "670px", marginTop: "-40px" }}
        >
          <g opacity="0.8">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M111.319 47.6145C129.481 56.5353 144.087 69.9808 152.715 85.6963C155.352 90.4393 158.172 99.2402 158.488 103.532C158.721 106.738 158.571 107.122 157.419 107.673C155.833 108.424 155.732 108.291 154.849 101.962C152.231 83.9094 132.485 61.8796 108.681 50.553C103.255 47.9636 93.89 44.9059 92.6878 45.3064C92.3372 45.4232 92.4874 46.0411 93.0381 47.0265C98.4288 56.1953 101.682 69.9726 100.461 78.3385C99.0403 88.1072 91.36 91.1115 82.997 85.2151C73.165 78.2497 66.3562 64.3214 67.8941 54.2355C68.9472 47.5394 74.3908 42.1634 81.0522 41.2797C83.9405 40.9296 83.9406 40.7626 81.5036 38.2906C76.1288 33.0127 68.7338 28.8532 58.6507 25.4781C43.9935 20.5657 31.0552 18.8098 22.5071 20.5448C19.2181 21.1954 17.9493 21.0616 17.5655 19.9093C16.9148 17.9554 23.3761 15.9695 30.5049 15.9875C38.3348 15.939 52.2578 18.9809 63.5595 23.1746C73.3587 26.8669 79.5517 30.759 85.7443 37.3229L89.1494 40.8638L93.6902 41.6328C99.5667 42.5691 104.408 44.24 111.319 47.6145ZM92.8472 84.2151C96.153 83.1136 97.6734 77.3194 96.6398 69.5374C95.8063 63.0246 92.3859 52.5871 90.0493 49.2469C89.9904 49.1586 89.915 49.0464 89.8273 48.916C89.4174 48.3063 88.7388 47.297 88.2301 46.4578C87.0951 44.7209 86.7613 44.5538 84.3572 44.6869C81.1183 44.8199 76.6271 46.372 75.2578 47.7745C72.5193 50.5794 71.2163 54.5201 71.5326 59.3127C72.1981 71.0022 85.5844 86.635 92.8472 84.2151ZM23.0925 8.10537C27.5341 4.23208 29.6212 2.98006 30.6228 3.48122C32.0084 4.13275 31.5237 6.68761 29.9209 7.22166C27.717 7.95599 15.7779 19.5595 16.1283 20.6116C16.195 20.812 19.3 22.1151 23.0728 23.4184C32.6884 26.8936 38.6646 29.8004 39.1485 31.2533C39.8326 33.3074 37.5287 33.4072 32.9713 31.4191C30.6843 30.4 26.8448 28.8964 24.4409 28.0275C21.9869 27.1754 18.4478 25.7386 16.5782 24.8031C9.63374 21.4953 10.7195 18.7402 23.0925 8.10537Z"
              fill="url(#paint0_linear_4040_9208)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_4040_9208"
              x1="23.9545"
              y1="24.9768"
              x2="135.256"
              y2="63.5974"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#22DF79" />
              <stop offset="1" stopColor="#29DEEC" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </section>
  );
}

export default Hero;
