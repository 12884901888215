import { useAtom } from "jotai";
import React from "react";
import { formdataAtom } from "../../lib/atom";

const CheckBox = ({ label, name, value }) => {
  const [formdata, setFormdata] = useAtom(formdataAtom);
  return (
    <>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id={name + value}
          name={name}
          value={value}
          onChange={(e) => {
            setFormdata({ ...formdata, [name]: e.target.checked });
          }}
          checked={value === true}
          style={{ border: "1px solid black" }}
        />
        <span className="form-check-label" htmlFor={name + value}>
          {label}
        </span>
      </div>
    </>
  );
};

export default CheckBox;
