import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import { Col, Card, Collapse } from 'react-bootstrap';
import { preview } from '@cloudinary/url-gen/actions/videoEdit';
const Testimony = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const testimonyData = [
  {
      name: "Anna Onaye Ututu",
      cohort: "Cohort 2 Participant",
      preview: `The Employment Bootcamp came into my life like a tsunami. With every lesson, ...`,
      text: `The Employment Bootcamp came into my life like a tsunami. With every lesson, every live review session, every game night they teased things out of 
      me that I was sure were either lost or that I never even had. I would highly recommend it to the world.`,
      avatar: "./assets/quotes/Anna-Onaye-Ututu_.jpg",
    },
  
    {
      name: "Abdulmalik Abdulmalik",
      cohort: "Cohort 2 Participant",
      preview: `Before joining the bootcamp, I had a survival mindset, believing that one's...`,
      text: `Before joining the bootcamp, I had a survival mindset, believing that one's studies, passions, or peace of mind were largely irrelevant in the job market, especially in Nigeria. I thought it was about taking any available job to survive. However, the bootcamp shifted my perspective. I realised the importance of self-improvement and proper preparation for job opportunities. The guidance I received helped me effectively navigate the challenges of job searching and career building.`,
      avatar: "./assets/quotes/Abdulmalik-Abdulmalik_.jpg",
    },

      {
      name: "Latifat Edu",
      cohort: "Cohort 3 Participant",
      preview: `The version of me that came into the bootcamp was a confused, disorganised,...`,
      text: `The version of me that came into the bootcamp was a confused, disorganised, detached, hopeless person who was about to give up.
      The version that leaves the bootcamp is not that disorganised or confused anymore because I now have purpose and direction and I know what I want in life and how to go about achieving goals or plans. I now own my thoughts and I get things done more easily without any pressure.`,
      avatar: "./assets/quotes/Latifat-Edu_.jpg",
    },

     {
      name: "Odo Oqua",
      cohort: "Cohort 2 Participant ",
      preview: `7 intense levels (weeks) into the program and bootcampers were already...`,
      text: `7 intense levels (weeks) into the program and bootcampers were already declining offers and landing opportunities that met most of their expectations. Keeping up with my fellow bootcampers who made it through the 12 weeks, I realised that 80% of us secured quality opportunities DURING and shortly after the bootcamp.`,
      avatar: "./assets/quotes/Odo-Oqua_.jpg",
    },
     {
      name: "Ophelia Annang",
      cohort: "Cohort 2 Participant ",
      preview: `I have been doing a lot of certifications but The Bootcamp has really...`,
      text: `I have been doing a lot of certifications but The Bootcamp has really pushed me. Right now I feel fearless, bold, and courageous. I feel I can do anything, Just bring it on.`,
      avatar: "./assets/quotes/Ophelia-Annang_.jpg",
    },


    {
      name: "Peace Chidimma Nduagu",
      cohort: "Cohort 2 Participant ",
      preview: `The person that came into The Bootcamp was scared, uncertain, and all...`,
      text: `The person that came into The Bootcamp was scared, uncertain, and all about employment but the person leaving The Bootcamp knows what she wants to do, she has so much clarity that she can create her own opportunities.`,
      avatar: "./assets/quotes/Peace-Chidimma-Nduagu_.jpg",
    },

    //{
    //  name: "Jeremiah Osemudiamen",
    //  cohort: "Cohort 4 Participant",
    //  preview: `The Employment Bootcamp has been one of the most transformative journeys...`,
    //  text: `The Employment Bootcamp has been one of the most transformative journeys of 2024 for me. From understanding who I am, to building my persona, brand to designing my career and creating my own story, The Employment Bootcamp has opened my eyes to the countless possibilities and opportunities I possess.`,
    //  //avatar: "./assets/quotes/anita.jpg",
    //},
     
  
   // {
   //   name: "Igoje Michael",
   //   cohort: "",
   //   text: `This journey of self-discovery has shaped my life's perspective, my thought process and my worldview with everything consciously centering around my values.<br/><br/>`,
   //   avatar: "./assets/quotes/igoje.jpg",
   // },
   
    ];

    const settings = {
        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
         responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    };

    const getCardWidth = () => {
      if (window.innerWidth <= 600)
        return '100%';
      else if (window.innerWidth <= 1024)
        return '16rem';
      else
        return '20rem'
    }

    return (
        <div style={{ background: '#F2F4F5' }}>
            <div className="container" style={{ padding: '40px' }}>
                <div className="section-title mt-3">
                    <h2 className="text-center text-black font-family-Raleway">
                        <span>
                            <svg style={{ marginTop: '-40px' }} width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M12.0705 20.9499C9.0984 20.2356 6.23627 20.2823 3.22162 20.1967C2.63287 20.1801 2.1434 20.632 2.12567 21.2057C2.10794 21.7796 2.57256 22.2586 3.16131 22.2756C6.01991 22.3567 8.74017 22.2912 11.5633 22.9691C12.1343 23.1064 12.7124 22.765 12.8507 22.2077C12.9926 21.6507 12.6415 21.0868 12.0705 20.9499Z" fill="#161B23" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M17.8309 14.8815C13.2983 10.0355 7.46761 5.46945 1.58016 2.27801C1.0659 1.9997 0.416831 2.18098 0.133099 2.68285C-0.150634 3.18471 0.0338085 3.81828 0.548072 4.09659C6.24045 7.18266 11.8797 11.5986 16.2598 16.2846C16.657 16.7081 17.3309 16.7375 17.7636 16.3504C18.1963 15.9633 18.2282 15.3051 17.8309 14.8815Z" fill="#161B23" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M22.7834 13.6539C23.5318 9.22171 22.255 4.65326 20.4498 0.62307C20.2121 0.0972884 19.5844 -0.142562 19.0488 0.0872304C18.5097 0.317368 18.265 0.93119 18.4991 1.45698C20.1447 5.12741 21.3648 9.27855 20.6838 13.3153C20.5881 13.8817 20.9818 14.4172 21.5599 14.5104C22.138 14.604 22.6877 14.2199 22.7834 13.6539Z" fill="#161B23" />
                            </svg>
                        </span>
                        DON’T TAKE OUR WORD FOR IT
                    </h2>
                </div>
                {/*<Slider {...settings}>
                    {testimonyData.map((testimony, index) => (
                        <div key={index}>

                         <Col  className="mb-4">
                                        <Card className="p-4" style={{ background: 'white', borderRadius: '8px' }}>
                                            <div className="d-flex align-items-center mb-4">
                                                <div style={{ width: '60px', height: '60px', position: 'relative', top: '-80px' }}>
                                                    <img className="rounded-circle" style={{ width: '60px', height: '60px' }} src={testimony.avatar} alt="Avatar" />
                                                </div>
                                                <div className="ms-3">
                                                    <h3 style={{ color: '#161B23', fontSize: '24px', fontFamily: 'Raleway', fontWeight: 600, lineHeight: '28.80px' }}>{testimony.name}</h3>
                                                    <p style={{ color: '#252D3C', fontSize: '16px', fontFamily: 'Raleway', fontWeight: 500, lineHeight: '24px', wordWrap: 'break-word' }}>
                                                          <div dangerouslySetInnerHTML={{ __html: testimony.text }} />
                                                    </p>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                       </div>
                    ))}
                </Slider>*/}

                <Slider {...settings}>
                  {testimonyData.map((testimony, index) => (
                    <div key={index}>
                      <Col className='mb-3'>
                        <Card className='testimony-con' style={{ width: getCardWidth(), height: "25rem", backgroundImage: `url(${testimony.avatar})`, backgroundPosition: "center 20%", backgroundSize: "cover", backgroundRepeat: "no-repeat", position: "relative", boxShadow: "10px 20px 200px black inset", borderRadius: "16px" }}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        >
                          <div className="img-con text-start" style={{ position: "absolute", bottom: "0", padding: "8px"}}>
                            <h3 style={{ color: '#fff', fontSize: '20px', fontFamily: 'Raleway', fontWeight: 600, lineHeight: '28.80px', padding: '0', display: "flex", flexDirection: "column", padding: "8px" }}>
                              {testimony.name}
                              <span style={{ fontFamily: "Raleway", fontSize: "10px" }}>{testimony.cohort}</span>
                            </h3>
                              <p style={{ color: '#fff', fontSize: '14px', fontFamily: 'Raleway', fontWeight: 400, wordWrap: 'break-word', padding: "8px" }}>
                                {hoveredIndex === index ? testimony.text : testimony.preview}
                              </p>
                          </div>
                        </Card>
                      </Col>
                    </div>
                  ))}
                </Slider>
            </div>
        </div>
    );
};

export default Testimony;
