import { atomWithStorage } from "jotai/utils";

export const isUpdatingAtom = atomWithStorage("isUpdating", false);

export const formdataAtom = atomWithStorage("formdata", {
  firstname: "",
  lastname: "",
  middlename: "",
  gender: "",
  country: "",
  city: "",
  email: "",
  phone: "",
  referral: "",
  referralPerson: "",
  consent: true,
  referrer: "",
  readinessFocus: "",
  adaptabilityFocus: "",
  feedbackFocus: "",
  growthFocus: "",
  educationFocus: "",
  highestQualification: "",
  pdf: null,
  jobRange: 0,
  whenWillYouBeDoneServing: "",
  whyLeaveCurrentPosition: "",
  whenLeavingCurrentPosition: "",
  employmentStatus: "",
  anotherJobText: "",
  anotherJobText1: "",
  unemployedDuration: "",
  workExperience: "",
  everLeadPeople: "",
  whyParticipate: "",
  whatDistractsYou: "",
  whatYouFindMostChallenging: "",
  expectedDifference: "",
  eligibilityStatus: "not measured",
});

export const cohortAtom = atomWithStorage("cohort", {
  id: "",
  name: "",
});

// Video progress tracking for the selection video
export const videoProgressAtom = atomWithStorage("videoProgress", 0); // Tracks video progress in seconds

// Track if video has ended
export const isVideoEndedAtom = atomWithStorage("isVideoEnded", false);

// Form submission attempt counter (for retrying video questions)
export const attemptCounterAtom = atomWithStorage("attemptCounter", 0);

export const hasUploadedCVAtom = atomWithStorage("hasUploadedCV", false);

// application progress
export const progressAtom = atomWithStorage("progress", 1);

// refferal code
export const referrerVendorAtom = atomWithStorage(
  "referralVendor",
  "naomi-lucas"
);

export const percentageAtom = atomWithStorage(
  "percentage",
  (get) => get(progressAtom) * 16.67
);

// check login state
export const isLoggedInAtom = atomWithStorage(false);

// date selection
export const selectedDateAtom = atomWithStorage("selectedDate", {
  startDate: null,
  endDate: null,
});
