import React from "react";

const NotFound = () => {
  return (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: "100vh", color: "black"}}
    >
      <h1 className="text-center">You are lost</h1>
    </div>
  );
};

export default NotFound;
