import { useAtom } from "jotai";
import { formdataAtom } from "../lib/atom";

export const useSetRegistratioData = () => {
  const [formdata, setFormdata] = useAtom(formdataAtom);

  const setRegistrationData = (regObj) => {
    regObj = replaceNulls(regObj);
    setFormdata({
      ...formdata,
      firstname: regObj.firstname,
      middlename: regObj.middlename,
      lastname: regObj.lastname,
      gender: regObj.gender,
      country: regObj.country,
      city: regObj.city,
      email: regObj.email,
      phone: regObj.phone,
      referralInput: regObj.referral,
      referralPerson: regObj.referralPerson,
      consent: regObj.consent === 1 ? true : false,
      referrer: regObj.referrer,
      employmentStatus: regObj.employmentStatus,
      unemployedDuration: regObj.unemployedDuration,
      workExperience: regObj.workExperience,
      whenWillYouBeDoneServing: regObj.whenWillYouBeDoneServing,
      whyLeaveCurrentPosition: regObj.whyLeaveCurrentPosition,
      whenLeavingCurrentPosition: regObj.whenLeavingCurrentPosition,
      freelancerText: regObj.freelancerText,
      nyscText: regObj.nyscText,
      anotherJobText: regObj.anotherJobText,
      highestQualification: regObj.highestQualification,
      educationFocus: regObj.educationFocus,
      growthFocus: regObj.growthFocus,
      feedbackFocus: regObj.feedbackFocus,
      adaptabilityFocus: regObj.adaptabilityFocus,
      readinessFocus: regObj.readinessFocus,
      lead: regObj.lead,
      participationReason: regObj.participationReason,
      distractions: regObj.distractions,
      challenging: regObj.challenging,
    });
  };

  const replaceNulls = (obj) => {
    const newObj = {};

    for (const key in obj) {
      newObj[key] = obj[key] === null ? "" : obj[key];
    }

    return newObj;
  };

  return { setRegistrationData };
};
