import { useAtom } from "jotai";
import { formdataAtom } from "../lib/atom";

export const useFormValidation = (component) => {
  const [formdata] = useAtom(formdataAtom);

  const getFields = (component) => {
    const componentTypes = {
      biodata: ["firstname", "lastname", "gender", "country", "city"],
      communications: ["email", "phone", "referral", "referralPerson"],
      login: ["email"],
      eligibility: [
        "adaptabilityFocus",
        "readinessFocus",
        "educationFocus",
        "highestQualification",
        "growthFocus",
        "feedbackFocus",
      ],
      employment: [
        "employmentStatus",
        "unemployedDuration",
        "workExperience",
        "whenWillYouBeDoneServing",
        "whyLeaveCurrentPosition",
        "whenLeavingCurrentPosition",
      ],
      others: [
        "whatYouFindMostChallenging",
        "everLeadPeople",
        "whatDistractsYou",
        "expectedDifference",
      ],
      default: [],
    };
    return componentTypes[component] || componentTypes["default"];
  };

  const getMessage = (field) => {
    const messageTypes = {
      firstname: "Please tell us your first name",
      lastname: "Please tell us your last name",
      gender: "Choose a gender",
      country: "What country are you from?",
      city: "Country and city of residence is invalid",
      email: "Please provide a valid email address",
      phone: "Phone number is required",
      referral: "Tell us how you heard about us",
      referralPerson: "Tell us the person or organisation",
      adaptabilityFocus: "Please tell us how adaptable you are",
      readinessFocus: "Please let us know about your readiness",
      educationFocus: "Please let us know what best describes your educational focus",
      highestQualification: "Let us know your highest level of education",
      growthFocus: "Let us know how you go about performing a task you have never done before",
      feedbackFocus: "Please let us know how you deal with feedback",
      employmentStatus: "Please choose your employment status",
      unemployedDuration: "Let us know how long you have been out of a job",
      whyLeaveCurrentPosition: "Let us know why you want to leave your present job",
      whenLeavingCurrentPosition: "Let us know when you are looking to leave",
      whenWillYouBeDoneServing: "Let us know when you will be done with service",
      workExperience: "Let us know how long you have worked",
      jobRange: "Let us know how badly you need a job",
      hasUploadedCV: "Please attach your CV as a PDF or Word file.",
      participationReason: "Kindly tell us why you are filling this application form and what you wish to make out of it",
      whatYouFindMostChallenging: "Please tell us about the most challenging thing you've ever done.",
      everLeadPeople: "Please provide detailed examples of your leadership experience, including specific responsibilities and achievements.",
      whatDistractsYou: "Please explain your strategies for managing distractions. Provide specific examples of techniques you use to stay focused.",
      expectedDifference: "What difference are you hoping The Employment Bootcamp will make in your life?",
      default: "",
    };

    return messageTypes[field] || messageTypes["default"];
  };

  const validate = async () => {
    const fields = getFields(component);
    const errors = [];
    
    for (const field of fields) {
      const fieldValue = formdata[field] || ''; // Default to empty string if field is undefined

      if (field === "referralPerson" && !validateReferralPerson()) continue;
      if (field === "unemployedDuration" && !validateUnemployedDuration()) continue;
      if (field === "whyLeaveCurrentPosition" && !validateWhyAndWhenLeaveCurrentPosition()) continue;
      if (field === "whenLeavingCurrentPosition" && !validateWhyAndWhenLeaveCurrentPosition()) continue;
      if (field === "whenWillYouBeDoneServing" && !validateWhenWillYouBeDoneServing()) continue;

      if (!fieldValue.trim()) {
        const err = getMessage(field);
        errors.push(err);
      }
    }

    return errors;
  };

  const validateReferralPerson = () => {
    return (
      formdata.referral === "It was recommended by a friend or a family member" ||
      formdata.referral === "Through an organisation"
    );
  };

  const validateUnemployedDuration = () => {
    return formdata.employmentStatus === "I am unemployed and actively looking for a job";
  };

  const validateWhyAndWhenLeaveCurrentPosition = () => {
    return formdata.employmentStatus === "I am employed but looking for another job";
  };

  const validateWhenWillYouBeDoneServing = () => {
    return formdata.employmentStatus === "I am currently serving (NYSC)";
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const validatePhone = (phone) => {
    const re = /^(\+?[0-9]{1,4})?[-.\s]?(\(?\d{1,4}\)?[-.\s]?){1,4}(\d{1,4}[-.\s]?\d{1,9})$/;
    return re.test(phone);
  };

  return { validate, validateEmail, validatePhone };
};
