import { useAtom } from "jotai";
import React from "react";
import { formdataAtom } from "../../lib/atom";

const TextInputField = ({
  label,
  name,
  type = "text",
  placeholder,
  value,
  disabled = false,
}) => {
  const [formdata, setFormdata] = useAtom(formdataAtom);
  return (
    <>
      {label && (
        <label
          htmlFor={name}
          className="form-label"
          style={{ color: "black", fontWeight: "bold" }}
        >
          {label}
        </label>
      )}
      <input
        type={type}
        className="form-control"
        id={name}
        value={value}
        name={name}
        aria-describedby="textInputHelp"
        placeholder={placeholder}
        disabled={disabled}
        onChange={(e) => {
          setFormdata({ ...formdata, [name]: e.target.value });
        }}
      />
    </>
  );
};

export default TextInputField;
