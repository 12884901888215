import React from "react";

const SaveAndContinueButton = ({ isLoading, handleForm }) => {
  return isLoading ? (
    <button disabled type="button" className="btn btn-primary" id="btnLoading">
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </button>
  ) : (
    <button
      onClick={handleForm}
      type="button"
      className="btn btn-primary"
      style={{
        backgroundColor: "#ECA239",
        color: "black",
        border: "1px solid #ECA239",
      }}
    >
      Save and continue
    </button>
  );
};

export default SaveAndContinueButton;
