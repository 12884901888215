import React, { useEffect, useState } from "react";
import TextInputField from "../../Form/TextInputField";
import { progressAtom, isUpdatingAtom, formdataAtom } from "../../../lib/atom";
import { useAtom } from "jotai";
import Radio from "../../Form/Radio";
import CheckBox from "../../Form/CheckBox";
import { useNavigate } from "react-router-dom";
import { referralItems } from "../../../constants/referralItems";
import { useFormValidation } from "../../../hooks/useFormValidation";
import SaveAndContinueWithBackButton from "../../Utility/SaveAndContinueWithBackButton";
import { useUpdateApplication } from "../../../hooks/useUpdateApplication";

const Communications = () => {
  const router = useNavigate();
  const [formdata] = useAtom(formdataAtom);
  const { validate, validateEmail, validatePhone } = useFormValidation("communications");
  const { updateApplication } = useUpdateApplication();
  const [errors, setErrors] = useState([]);
  const [isUpdate] = useAtom(isUpdatingAtom);
  const [progress, setProgress] = useAtom(progressAtom);

  useEffect(() => {
    setProgress(3);
  }, [setProgress]);

  const handleComms = async () => {
    setErrors([]);

    const errs = await validate();
    if (errs.length) {
      setErrors(errs);
      return;
    }

    const emailIsValid = validateEmail(formdata.email);
    if (!emailIsValid) {
      setErrors(["The provided email is not valid"]);
      return;
    }

    const phoneIsValid = validatePhone(formdata.phone);
    if (!phoneIsValid) {
      setErrors(["The provided phone number is not valid"]);
      return;
    }

    if (isUpdate) {
      router("/apply/eligibility");
    } else {
      await updateApplication();
      setProgress(progress + 1);
    }
  };

  return (
    <div className="col-md-8 slide-col" id="communications">
      <h4
        className="text-start text-uppercase fw-bold"
        style={{ color: "black" }}
      >
        Communications
      </h4>
      <form>
        <div className="mb-4">
          <TextInputField
            name="email"
            type="email"
            disabled={isUpdate} // Disabled field to prevent further input
            placeholder="jane@naomilucas.xyz"
            label="Email"
            value={formdata.email}
          />
        </div>
        <div className="mb-4">
          <TextInputField
            name="phone"
            type="tel"
            placeholder="(234) 9030009900"
            label="Phone number"
            value={formdata.phone}
          />
        </div>
        <div className="mb-4">
          <label htmlFor="referralInput" className="form-label">
            How did you FIRST hear about The Employment Bootcamp? (Pick only one
            option)
          </label>

          <Radio
            items={referralItems}
            flex={false}
            name="referral"
            value={formdata.referral}
          />

          {(formdata.referral ===
            "It was recommended by a friend or a family member" ||
            formdata.referral === "Through an organisation") && (
            <div className="mb-4 ms-5" id="referrerInputDiv">
              <TextInputField
                name="referralPerson"
                type="text"
                placeholder="Alice Bob"
                label="If through an organisation or person, kindly write their full name below"
                value={formdata.referralPerson}
              />
            </div>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="consentInput"
            className="form-label"
            style={{ color: "black", fontWeight: "bold" }}
          >
            Consent
          </label>

          <CheckBox
            label="I consent to receiving communications about The Employment Bootcamp and related activities."
            name="consent"
            value={formdata.consent}
          />
        </div>
        <div className="mb-3 form-check text-end">
          {errors.map((error, index) => (
            <div key={index} className="alert alert-danger text-start">
              {error}
            </div>
          ))}

          <SaveAndContinueWithBackButton handleComms={handleComms} />
        </div>
      </form>
    </div>
  );
};

export default Communications;
