import { useAtom } from "jotai";
import {
  formdataAtom,
  isLoggedInAtom,
  cohortAtom,
  isUpdatingAtom,
} from "../lib/atom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useSetRegistratioData } from "./useSetRegistratioData";

export const useSaveEmailToDatabase = () => {
  const router = useNavigate();
  const [formdata] = useAtom(formdataAtom);
  const [, setIsUpdate] = useAtom(isUpdatingAtom);
  const { setRegistrationData } = useSetRegistratioData();
  const [, setIsLoggedIn] = useAtom(isLoggedInAtom);
  const [cohort] = useAtom(cohortAtom);

  const saveEmailToDatabase = async () => {
    const fd = new FormData();
    fd.append("email", formdata.email);
    const apiUrl = `${process.env.REACT_APP_BASE_URL}/cohort/${cohort.id}/get-registration/${formdata.email}`;
    try {
      const res = await axios.get(apiUrl, fd);
      setIsLoggedIn(true);
      const extractedData = res.data.data[0];
      setRegistrationData(extractedData);
      if (extractedData.updated_at) {
        setIsUpdate(true);
      }
      router(`/apply/${extractedData.page}`);
    } catch (error) {
      const msg = error?.response?.data?.message || error.toString();
      throw new Error(msg);
    }
  };

  return { saveEmailToDatabase };
};
