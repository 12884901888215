import { useAtom } from "jotai";
import { formdataAtom, isUpdatingAtom } from "../lib/atom";
import Swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const useUpdateApplication = () => {
  const router = useNavigate();
  const [formdata] = useAtom(formdataAtom);
  const [, setIsUpdate] = useAtom(isUpdatingAtom);

  const updateApplication = async () => {
    Swal.fire({
      title: "Confirm your email address",
      text: "You will not be able to change it after this. Continue ?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#ECA239",
      cancelButtonColor: "#d2d2d7",
      confirmButtonText: "Yes, continue",
      cancelButtonText: "Edit",
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        const fd = new FormData();

        fd.append("firstname", formdata.firstname);
        fd.append("middlename", formdata.middlename);
        fd.append("lastname", formdata.lastname);
        fd.append("gender", formdata.gender);
        fd.append("country", formdata.country);
        fd.append("city", formdata.city);
        fd.append("email", formdata.email);
        fd.append("phone", formdata.phone);
        fd.append("referralInput", formdata.referral);
        fd.append("referralPerson", formdata.referralPerson);
        fd.append("consent", formdata.consent);
        fd.append("referrer", formdata.referrer);

        const apiUrl = process.env.REACT_APP_BASE_URL + "/register";

        try {
          const res = await axios.post(apiUrl, fd);
          setIsUpdate(true);
          console.log(res);
        } catch (error) {
          console.log("ERRR::", error);
          const msg =
            error?.response?.data?.message || "An error occured. Try again";
          return Swal.showValidationMessage(msg);
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Almost there!",
          text: "You are almost done with your application",
          icon: "success",
          confirmButtonText: "Continue",
          confirmButtonColor: "#ECA239",
          preConfirm: async (login) => {
            router("/apply/eligibility");
          },
        });
      }
    });
  };

  return { updateApplication };
};
