import { Outlet } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import { useAtom } from "jotai";
import { cohortAtom, progressAtom } from "../lib/atom";
import { percentageAtom } from "../lib/atom";

const ApplyPage = ({ referralCode }) => {
  const [progress] = useAtom(progressAtom);
  const [percentage] = useAtom(percentageAtom);
  //const [cohort] = useAtom(cohortAtom);

  return (
    <>
      <div className="mt-5" id="applyContainer" style={{ color: "#212529" }}>
        <section id="applicationHeader" style={{ padding: "0px" }}>
          <div className="container">
            <div className="row mt-3 justify-content-center">
              <h2
                className="text-center text-uppercase fw-bolder"
                style={{ color: "#ECA239" }}
              >
                THE EMPLOYMENT BOOTCAMP APPLICATION
                {/*{referralCode && <p>Your referral code is: {referralCode}</p>}*/}
              </h2>
            </div>
          </div>
        </section>
        <section id="applicationMain" style={{ padding: "0px" }}>
          <div className="container">
            <div
              className="row mt-3 justify-content-center slide-row"
              id="sliderRow"
            >
              <Outlet />
            </div>
          </div>
          <section id="applicationFooter">
            <div className="container">
              <div className="row mt-3 justify-content-center">
                <div className="col-md-8">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: percentage + "%" }}
                      aria-valuenow="16.67"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                  <div className="text-end">
                    <span id="currentSectionDiv">{progress}</span> of 6
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default ApplyPage;
